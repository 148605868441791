<template>
  <div>
    <div v-if="$userInfo.isSupportUser || $userInfo.isCustomerAdministrator"
      class="tile is-parent pl-1">
      <article class="tile is-child box">
        <div class="columns is-flex is-align-items-center">
          <div class="column">
            <p class="title">License</p>
          </div>
          <div v-if="!hasError && showSavingStatus && !toastNotificationEnabled"
            class="column is-flex is-justify-content-flex-end is-align-items-center">
            <span v-if="saving"
              class="button is-loading is-white"
              style="height: 27px;" />
            <span v-else
              class="text has-text-success">
              <i class="mdi mdi-checkbox-marked-circle-outline mdi-18px" />
            </span>
            <span class="is-size-6"
              :class="saving ? 'has-text-warning' : 'has-text-success'">{{ savingStatus }}</span>
          </div>
        </div>

        <div class="field columns">
          <div class="column is-half">
            <label class="label">Max Users</label>
            <div class="control">
              <input class="input"
                type="number"
                v-model.number="companyLicense.maxUsers"
                @blur="$v.companyLicense.maxUsers.$touch()"
                :disabled="!$userInfo.isSystemAccount">
              <span class="help is-danger"
                v-if="$v.companyLicense.maxUsers.$dirty && !$v.companyLicense.maxUsers.required">Max User required
              </span>
              <span class="help is-danger"
                v-if="$v.companyLicense.maxUsers.$dirty && !$v.companyLicense.maxUsers.maxValueValue">Max number 100
              </span>
            </div>
          </div>
          <div class="column is-half">
            <label class="label">Concurrent Users</label>
            <div class="control">
              <input class="input"
                type="number"
                v-model.number="companyLicense.concurrentUsers"
                @blur="$v.companyLicense.concurrentUsers.$touch()"
                :disabled="!$userInfo.isSystemAccount">
              <span class="help is-danger"
                v-if="$v.companyLicense.concurrentUsers.$dirty && !$v.companyLicense.concurrentUsers.required">Concurrent User required
              </span>
              <span class="help is-danger"
                v-if="$v.companyLicense.concurrentUsers.$dirty && !$v.companyLicense.concurrentUsers.maxValueValue">Max number 100
              </span>
            </div>
          </div>
        </div>
        <div class="field columns">
          <div class="column is-half">
            <label class="label">License Type</label>
            <div class="control is-fullwidth">
              <span class="select is-fullwidth">
                <select v-model="companyLicense.licenseType"
                  :disabled="!$userInfo.isSystemAccount">
                  <option v-for="(type, typeIndex) in companyLicenseTypes"
                    :key="typeIndex"
                    :value="type">
                    {{ type }}
                  </option>
                </select>
              </span>
            </div>
          </div>
          <div class="column is-half">
            <label class="label">Expire Date</label>
            <v-date-picker
              v-model="companyLicense.expiryDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              @input="expireDateInputEvent"
              class="is-fullwidth"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field has-addons">
                  <div class="control is-expanded has-icons-left">
                    <input type="text"
                      class="input"
                      placeholder="Expiry date"
                      :value="inputValue"
                      v-on="inputEvents"
                      :disabled="!$userInfo.isSystemAccount">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
      </article>
    </div>
    <div v-else
      class="is-flex is-justify-content-center pt-5 ">
      <div class="notification is-danger is-flex is-justify-content-center is-size-4"
        style="width: 50%">
        You do not have permissions on this page
      </div>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import { CompanyLicenseTypes} from '@/enums'
import { maxValue, required } from 'vuelidate/lib/validators'
import StoreMixin from './storeMixin'

export default {
  name: 'CompanyLicenseSetting',
  mixins: [StoreMixin],
  data() {
    return {
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      autoUpdate: null,
      companyLicense: {},
      expiryDate: '',
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['DD/MM/YYYY'],
        data: ['DD/MM/YYYY'],
        dayPopover: null
      }
    }
  },
  computed: {
    companyLicenseTypes() {
      return CompanyLicenseTypes
    }
  },
  watch: {
    companyLicense: {
      handler: function(val) {
        this.$v.$touch()
        this.saveSnapshot({ entity: _cloneDeep(val) })
        this.autoUpdate(val)
      },
      deep: true
    }
  },
  async created() {
    this.$showSpinner()
    this.initializeAutoUpdate()
    this.companyLicense = _cloneDeep(await this.getStoreItem({ serviceFunction: 'getCompanyLicense' }))
    this.$hideSpinner()
  },
  methods: {
    expireDateInputEvent(event) {
      if (event !== null) {
        const dateTime = new Date(event)
        dateTime.setHours(0, 0, 0, 0)
        this.companyLicense.expiryDate = dateTime.toISOString()
      }
    },
    disableAutoUpdate() {
      if (typeof this.autoUpdate === 'function'
        && typeof this.autoUpdate.cancel === 'function') {
        this.autoUpdate.cancel()
      }
      this.autoUpdate = () => {}
    },
    initializeAutoUpdate() {
      this.autoUpdate = _debounce(async (value) => {
        this.snapshotDiff && await this.updateLicense(value)
      }, this.debounceDelay)
    },
    async updateLicense(license) {
      if (!this.$userInfo.isSystemAccount) return
      if (!this.snapshotDiff) return
      this.$v.$touch()
      if (this.$v.$error) return

      license.deepDiff = JSON.stringify(this.snapshotDiff)
      this.companyLicense = _cloneDeep(await this.editStoreItem({
        serviceFunction: 'updateCompanyLicense', entity: license }))

      if (this.toastNotificationEnabled) {
        this.openToastNotification()
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.disableAutoUpdate()
    this.toastNotificationEnabled = true
    await this.updateLicense(this.companyLicense)
    this.clearSnapshots()
    this.toastNotificationEnabled = false
    next()
  },
  validations: {
    companyLicense: {
      maxUsers: {
        required,
        maxValueValue: maxValue(100)
      },
      concurrentUsers: {
        required,
        maxValueValue: maxValue(100)
      }
    }
  }
}
</script>
